import { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Toast from '../components/toast'
import { useMoralis } from '../hooks/moralis'
import SaleNFTCard from '../components/SaleNFTCard'
import { marketContract } from '../hooks/config'

const NFTFeatured = () => {
  const [buyNowList, setBuyNowList] = useState()
  const { getNFTsByContract, Moralis } = useMoralis()
  useEffect(() => {
    const fetchData = () => {
      getNFTsByContract?.({ address: marketContract }).then((res: any) => {
        console.log(res)
        setBuyNowList(res.result)
      })
    }
    fetchData()
  }, [])

  return (
    <>
      {/* TODO: New Header Component */}
      <Header className="!max-w-[1845px] xl:!max-w-[1470px] lg:!max-w-[1095px] xmd:!max-w-[720px] sm:!max-w-[350px]" />
      <div className="bg-[#f3f7fa]">
        {/* Top Title */}
        <Toast limit={1} />
        <div className="h-[478px] bg-[url('../images/NFTRelated/bg.png')] bg-cover bg-no-repeat bg-center pt-20 sm:h-[386px] sm:pt-[57px]">
          <div className="flex flex-col items-center h-full mx-auto max-w-7xl md:px-16 sm:px-4">
            <div className="text-[42px] leading-[52px] font-boldText font-bold w-[537px] text-center mt-[70px] sm:mt-[50px] sm:w-[300px] sm:text-[32px] sm:leading-[42px]">
              Find, Collect, and Sell extraordinary NFTs
            </div>
            <div className="mt-[46px] flex sm:mt-[42px]">
              <button
                className="w-[144px] h-[50px] text-[14px] shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] rounded-[60px] text-white bg-gradient-to-r from-[rgba(100,181,246,0.7)] to-[rgba(30,136,229,1)] flex justify-center items-center coin-minting-btn hover:shadow-[0_6px_18px_4px_#c0e2ff]"
                onClick={() => {}}
              >
                Explore NFTs
              </button>
              <button
                className="w-[144px] h-[50px] text-[14px] shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] rounded-[60px] text-white ml-[15px] flex justify-center items-center coin-exchange-btn hover:shadow-[0_6px_18px_4px_#efd6d7]"
                onClick={() => navigate(`/createNFT`)}
              >
                Create NFT
              </button>
            </div>
          </div>
        </div>
        {/* Buy Now Content */}
        <div className="mt-[54px] mx-auto max-w-[1845px] md:px-16 sm:px-4 overflow-hidden sm:mt-8">
          <div className="text-[34px] leading-[52px] text-center font-boldText font-bold">
            Buy Now
          </div>
          <div className="mt-3 gap-x-[30px] gap-y-[33px] pb-14 grid grid-cols-5 max-w-[1845px] mx-auto xl:max-w-[1470px] xl:grid-cols-4 lg:max-w-[1095px] lg:grid-cols-3 xmd:max-w-[720px] xmd:grid-cols-2 sm:max-w-[350px] sm:grid-cols-1 sm:gap-y-4">
            {buyNowList?.map((x, i) => <SaleNFTCard key={i} NFTInfo={x} />) ||
              ''}
          </div>
          <div className="flex justify-center">
            <button
              className="border border-[#828282] rounded-[60px] w-[154px] h-[56px] flex justify-center items-center text-[#828282]"
              onClick={() => navigate('/NFTMarket')}
            >
              MORE
              <svg
                className="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>
        {/* Creators Content */}
        {/* <div className="mt-[114px] mx-auto px-8 md:px-16 sm:px-4 sm:mt-[54px]">
          <div className="text-[34px] leading-[52px] text-center font-boldText font-bold">
            Creators
          </div>
          <div className="mt-8 grid grid-cols-5 gap-x-[30px] gap-y-[33px] pb-14 max-w-[1845px] mx-auto xl:max-w-[1470px] xl:grid-cols-4 lg:max-w-[1095px] lg:grid-cols-3 xmd:max-w-[720px] xmd:grid-cols-2 sm:max-w-[350px] sm:grid-cols-1 sm:gap-y-4">
            {creatorsList.map((x, i) => (
              <NFTAuthorCard key={i} NFTAuthorInfo={x} />
            ))}
          </div>
          <div className="flex justify-center mt-[33px]">
            <button
              className="border border-[#828282] rounded-[60px] w-[154px] h-[56px] flex justify-center items-center text-[#828282]"
              onClick={() => navigate('/NFTCreators')}
            >
              MORE
              <svg
                className="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div> */}
      </div>
      <Footer className="bg-[#f3f7fa]" />
    </>
  )
}

export default NFTFeatured
